
* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
a:hover {
  text-decoration: none;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

table, td {
  border: 1px solid #666;
  border-collapse: collapse;
}

th, td {
  padding: 1px;
  margin: 0px;
}

h3, h4 {
  border-bottom: 1px solid #666;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  font-size: 21px;
  line-height: inherit;
  margin-bottom: 0px;
  border: none;
  font-weight: 500;
  text-transform: capitalize;
}

.thead {
  font-weight: 900;
}

.inline-block {
  display: inline-block;
}
/* Style for missing localization translations */
.translation_missing {
  /*  color: blue;*/
}

.error-messages {
  padding: 15px;
}

.tile-icon {
  width: 128px;
  height: 128px;
  margin-right: 10px;
  /* border: 8px solid #555; */
  box-shadow: 0 0 15px 10px black inset, 0 0 12px 1px #020202;
  border-radius: 50%;
  margin: auto;
  display: block;
  transition: 0.25s;
}
.grid-item:hover .tile-icon {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
  transition: 0.5s;
}
.focus, .tile-bg {
  transition: 0.25s
}
.grid-item:hover .focus {
  transition: 0.5s;
  fill: red !important;
}
.grid-item:hover .tile-bg {
  fill: #c75c5c !important;
}

.manage_devices_section {
  margin-bottom: 15px;
}
.device_summary_row {
  cursor: pointer;
  padding: 5px;
  padding-right: 30px;
  box-sizing: content-box;
  border: 1px solid;
  position: relative;
  background: rgba(255, 255, 255, 0.5);
}
.detail_row {
  display: none;
  cursor: default;
  margin-right: -25px;
}
.device_summary_row::after {
  content: '+';
  width: 10px;
  height: 10px;
  position: absolute;
  right: 12px;
  top: 5;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}
.device_summary_row::after, .device_header {
  font-size: 20px;
  line-height: 30px;
}
.device_summary_row span.device_header_badge {
  border: 1px solid #555;
  padding: 3px;
  white-space: nowrap;
  vertical-align: text-bottom;
  background: white;
  background: -moz-linear-gradient(top, white 0%, #f1f1f1 50%, #e1e1e1 51%, #f6f6f6 100%);
  background: -webkit-linear-gradient(top, white 0%, #f1f1f1 50%, #e1e1e1 51%, #f6f6f6 100%);
  background: linear-gradient(to bottom, white 0%, #f1f1f1 50%, #e1e1e1 51%, #f6f6f6 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f6f6f6',GradientType=0 );
}

.edit-payment-buttons {
  display: flex;
  justify-content: space-around;
}

.device_summary_row.open::after {
  content: '-';
}
.open .device_header {
  font-weight: 500;
}

.expand_all, .collapse_all {
  font-size: smaller;
}

.manage-devices-box {
  display: inline-block;
  padding: 5px 10px;
}

.manage-devices-box>table th {
  background-color: rgba(255,255,255,0.25);
}

.manage-devices-box>table td {
  background-color: rgba(255,255,255,0.15);
}

.action-cell {
  text-align: center;
  padding-top: 8px;
  background-color: transparent !important;
}

.rg-padding-large {
  padding: 10px;
}

.rg-no-border {
  border: 0;
}

.rg-tiny {
  font-size: 0.85em;
}

.rg-session-info {
  padding-top: 0px;
}

.rg-transparent-bg {
  background: $panel-background-color;
  border: 1px solid $panel-border-color;
  font-size: small;
  text-shadow: none;
}

.portal-graphs-container {
  .graph_dialog {
    border: 1px solid $panel-border-color;
  }
}

.rg-margin-left {
  margin-left: 1px;
  padding: 0;
}

.rg-padded {
  padding: 8px;
}

.rg-heading {
  color: #212529;
  /*text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;*/
  /*text-shadow: 0 0 3px #000, 0 0 6px #333, 0 0 9px #fff;*/
}

.rg-heading h1, .rg-heading h2, .rg-heading h3, .rg-heading h4 {
  color: #212529;
  /*text-shadow: 1px 0 black;*/
  /*text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;*/
  /*text-shadow: 0 0 3px #333, 0 0 7px #fff;*/
}

.rg-yield h3, .rg-yield h4 {
  border-bottom: 1px solid #ddd;
}

.rg-shadow {
  text-shadow: 0 0 3px #333, 0 0 7px #fff;
}

.rg-purchase {
}

.rg-purchase-button {
  text-shadow: none;
}

.rg-form {
  width: 100%;
  padding: 4%;
  /*padding: 10px;
  /*margin: 10px;*/
  margin-bottom: 10px !important;
  /*margin-top: 10px;*/
  background: rgba(245,245,245,0.9);
  border: 1px solid #4d4d4d;
  /* border-radius: 5px; */
}

.rg-form legend {
  padding: 0 0 5px;
  margin: 0;
}

.rg-form legend:after {
  border-bottom: 1px solid #666;
  padding-bottom:5px;
}

.rg-underline {
  border-bottom: 1px solid #666;
  padding-bottom: 1px;
  margin-top: 5px;
  margin-bottom: 5px;
}


/*
.rg-button {
  background: rgb(255,255,255);
  background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(241,241,241,1) 50%, rgba(225,225,225,1) 51%, rgba(246,246,246,1) 100%);
  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(241,241,241,1) 50%,rgba(225,225,225,1) 51%,rgba(246,246,246,1) 100%);
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(241,241,241,1) 50%,rgba(225,225,225,1) 51%,rgba(246,246,246,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f6f6f6',GradientType=0 );
  border: 1px solid #444;
  padding: 0px 32px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  max-width: 300px;
  min-width: 200px;
  margin: auto;
}
*/

.uk-form input.rg-button {
  font-size: 16px;
  line-height: 30px;
}

.rg-button-inline {
  display: inline-block;
}

/*
.rg-button:hover {
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.24), 0 3px 4px 0 rgba(0, 0, 0, 0.19);
  background: rgb(255,255,255);
  background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(220,232,250,1) 50%, rgba(204,222,246,1) 51%, rgba(228,237,250,1) 100%);
  background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(220,232,250,1) 50%,rgba(204,222,246,1) 51%,rgba(228,237,250,1) 100%);
  background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(220,232,250,1) 50%,rgba(204,222,246,1) 51%,rgba(228,237,250,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e4edfa',GradientType=0 );
}
*/
/*
.rg-button-red-hover:hover {
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.24), 0 3px 4px 0 rgba(0, 0, 0, 0.19);
  background: rgb(255,241,239);
  background: -moz-linear-gradient(top,  rgba(255,241,239,1) 0%, rgba(255,218,213,1) 50%, rgba(255,190,181,1) 51%, rgba(255,227,223,1) 100%);
  background: -webkit-linear-gradient(top,  rgba(255,241,239,1) 0%,rgba(255,218,213,1) 50%,rgba(255,190,181,1) 51%,rgba(255,227,223,1) 100%);
  background: linear-gradient(to bottom,  rgba(255,241,239,1) 0%,rgba(255,218,213,1) 50%,rgba(255,190,181,1) 51%,rgba(255,227,223,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff1ef', endColorstr='#ffe3df',GradientType=0 );
}
*/

.no-min-width {
  min-width: 0;
}
.small-min-width {
  min-width: 65px;
  padding: 0 5px;
}

/*
.activated {
  background: linear-gradient(to bottom, #d6f3d2 0%, #ecf9eb 50%, #b0efac 51%, #d6f3d2 100%);
}
*/

.validated_icon:after {
  position: absolute;
  /* top: 6px; */
  padding: 6px;
  right: 12px;
  content: "\f058";
  font-family: 'Font Awesome 5 Pro';
  text-align: right;
  vertical-align: middle;
  height: 17px;
  width: 15px;
  z-index: 10000;
  font-size: 15px;
  color: #02a100;
}

.relative {
  position: relative
}

.validated_contact_info_field {
  box-shadow: 0px 0px 5px inset #00a100;
  padding-right: 26px !important;
}

.rg-navbar {
  padding: 5px 0 12px;
  border-top-style: none;
  border-right-style: none;
  border-bottom: 1px solid #333333;
  border-left-style: none;
  border-image-source: none;
  border-image-outset: 0 0 0 0;
  border-image-repeat: stretch stretch;
  background-color: $navbar-nav-active-background;
  background-image: none;
  background-repeat: repeat;
  background-attachment: scroll;
  background-position: 0 0;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: auto auto;
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 0;
}

.rg-center-content {
  margin-top: 66px
}

.rg-footer {
  border-top: 1px solid #aaa;
  padding-bottom: 5px;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  top: inherit;
}

.rg-development-bar {
  z-index: 500;
  position: fixed;
  background-color: yellow;
  bottom: 0px;
  width: 100%;
}

.rg-yield {
  padding-top:25px;
}

.rg-payment-form > * {
  padding: 15px;
}

.uk-slideshow, .uk-slideshow>li {
  height: 100% !important;
}

.rg-cover {
  position: fixed !important;
  top: 66px;
  height: 100%;
  width: 100%;
  z-index: -1;
}

/* This element defines the screen door effect in front of the
image.  Simply make background-image: none to remove it.  Or
use any semi-transparent image as the background and it will
be overlayed on top of the slideshow images. */
.rg-cover-object {
  padding: 5px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAASUlEQVQoU2N0d3N/tnPXTikGKADxQUx0MUaQIEwxsiZ0MbBCZMUwProYiokwSWSrYSYzYrMO3TSQGuIVYtON1WqyPIM3eIgNcADkp3bz9i5yegAAAABJRU5ErkJggg==) repeat;
  background-size: 8px 8px;
}

/* affects graphs that result in the "no data to display"
message when a graph has no available data */
.portal-graphs-container > div > h2 {
  font-size: 16px;
  margin: 8px;
}

@media (max-width: 865px) {
  .rg-yield {
    /*max-height: 400px;*/
    overflow: auto;
  }

  .rg-cover {
    min-height: 590px;
    top: 58px;
  }

  .rg-overflow {
    overflow: auto;
    height: 470px;
  }

}

@media (min-width: 866px) {
  .rg-cover {
    min-height: 490px;
  }

  .rg-overflow {
    overflow: auto;
    height: 470px;
  }

}

@media (min-width: 960px) {
  .rg-overflow {
    overflow: auto;
    height: 600px;
  }

}

@media (min-width: 1220px) {
  .rg-overflow {
    overflow: hidden;
    height: 740px;
  }

}

.rg-navbar-nav {
  padding-top: 8px;
  padding-right: 17px;
  float: right;
}

ul.rg-navbar-nav li.rg-first {
  border-left: none;
}

ul.rg-navbar-nav li > a {
  font-weight: bold;
  font-size: 1.0em;
  color: #383A3B;
  background: none !important;
}

ul.rg-navbar-nav li {
  /* border-left: 2px solid #888; */
}

.rg-navbar-nav>li.uk-active>a:hover,
.uk-navbar-toggle:focus,
.uk-navbar-toggle:hover,
ul.rg-navbar-nav li > a:hover {
  color: $link-hover-color;
  font-weight: bold;
}

.rg-navbar-nav > li.uk-active > a {
  color: $primary-link-color !important;
}

#rg-offcanvas ul>li {
  float: none;
}

#rg-offcanvas ul > li > a {
  color: #FFF;
}
#rg-offcanvas ul > li > a:hover {
  color: $button-hover-color;
}

#offsite_purchase_button {
  text-align: center;
}

.rg-pricing table {
  margin-bottom: 10px;
}

.rg-transaction-failed {
  background-color: rgba(210, 0, 0, 0.36);
}

.flash-message-container {
  max-width: 500px;
  margin: auto;
  text-align: center;
}

.uk-close {
  float: right;
}

.accessible_text {
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  top: -10px;
}

.center-align {
  text-align: center;
}




/*  SOCIAL LOGIN BUTTONS */

.account-info-social-login-btn.social-login-btn {
  padding-top:.375rem;
  padding-bottom:.375rem;
  padding-left:.375rem;
  padding-right:.375rem;
}
.social-login-btn {
  @extend .btn;
  margin-top:5px;
  width:100%;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .social-login-btn {
    svg {
      display: none;
    }
  }
}

/* inlude -custom in the class names to prevent AdBlockPlus from blocking
   the social media login buttons */
.btn-facebook, .btn-facebook-custom {
  svg {
    width:21px;
    float:left;
  }
}

.btn-twitter, .btn-twitter-custom {
  svg {
    width:21px;
    float:left;
  }
}
.btn-googleplus,.btn-googleplus-custom,.btn-outline-googleplus,.btn-outline-googleplus-custom {
  border:1px solid #d4d4d4;
  svg {
    width:21px;
    float:left;
  }
}
.btn-google_oauth2,.btn-google_oauth2-custom,.btn-outline-google_oauth2, .btn-outline-google_oauth2-custom {
  border:1px solid #d4d4d4;
  svg {
    width:21px;
    float:left;
  }

}

.btn-instagram, .btn-instagram-custom {
  i.fab.fa-instagram {
    width:21px;
    height:21px;
    float:left;
    border-radius:2px;
    font-size: 1.63em;
    margin-top: 0;
    padding-top: 0;
  }
}

.btn-linked_in, .btn-linked_in-custom {
  svg {
    width:21px;
    float:left;
    border-radius:2px;
    path {
      fill:#fff;
    }
  }
}

.btn-qrcode, .btn-qrcode-custom {
  svg {
    width:21px;
    float:left;
    border-radius:2px;
    path {
      fill: #fff;
    }
  }
}

i.fab.icon-remove:after {
  width: 10px;
  height: 10px;
  content: '\f00d';
  position: absolute;
  margin-left: -7px;
  margin-top: -33px;
  font-family: "Font Awesome 5 Pro";
  font-size: 18px;
  color: #5e5e5e;
  border:1px solid #5e5e5e;
  border-radius:10px;
}
#social_login .coupon-code {
  margin-left:25%;
  margin-right:25%;
}
/*
@media only screen and (max-width:746px) {
  .left-grid {
    h2 {
      font-size:15pt;
    }
  }
}
 */

legend span {
  font-size:x-small;
}

.icon-only {
  .social-login-btn {
    /*
    span {
      display:none;
    }
    */
    max-width:33px;
    float:left;
    margin-left:5px;
    margin-right:0;
  }
}

.social-login-btn.connected {
  min-width: 70px;

  img {
    border-radius: .25rem;
    float: right;
    margin-left: 4px;
    max-width: 32px;
  }
}



/*
.social-login-btn.connected:after {
  position: absolute;
  padding: 6px;
  right: 0;
  content: "\f14a";
  content: "\f05d";
  font-family: "Font Awesome 5 Pro";
  text-align: right;
  vertical-align: middle;
  height: 17px;
  width: 15px;
  z-index: 10000;
  font-size: 18px;
  color: #02a100;
}
*/

/*
@media only screen and (max-width: 520px) {
  #social_login .coupon-code {
    margin-left:0;
    margin-right:0;
  }
  .social-login-btn {
    span {
      display:none;
    }
    max-width:33px;
    float:left;
    margin-left:5px;
    margin-right:0;
  }
  .left-grid,.right-grid {
    .rg-button {
      margin-left:10%;
      margin-right:10%;
      width:80%;
    }
  }
  .left-grid {
    h2 {
      font-size:10pt;
    }
  }
}
 */
/*
@media only screen and (max-width: 400px) {
  .left-grid,.right-grid {
    .rg-button {
      margin-left:0;
      margin-right:0;
      width:100%;
    }
  }
}

 */




/* END SOCIAL LOGIN BUTTONS */
