$header-height: 65px !default;
$footer-height: 100px !default;
$footer-img-top-bottom-padding: 10px !default;
$footer-img-side-padding: 10px !default;
$footer-bg: #f5f5f5 !default;
$footer-border-top: #323232 !default;

/*  
Sticky Footer Solution
by Steve Hatcher 
http://stever.ca
http://www.cssstickyfooter.com
*/

* {
  margin: 0;
  padding: 0;
}

/* must declare 0 margins on everything, also for main layout components use padding, not 
vertical margins (top and bottom) to add spacing, else those margins get added to total height 
and your footer gets pushed down a bit more, creating vertical scroll bars in the browser */

html {
  min-height: 100%;
  position: relative;
}

#main {
  overflow: auto;
  padding: $header-height 0 0;
  margin-bottom: 107px;
  /*padding: $header-height 0px ($footer-height + 30px);*/
}

footer {
  /*
  margin-top: $footer-height - ($footer-height * 2);
  height: $footer-height;
  padding-bottom: $footer-height;
   */
  background: $footer-bg;
  border-top: 1px solid $footer-border-top;
  bottom: 0;
  clear: both;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 5px;

  img {
    display: inline-block;
    margin: auto;
    max-height: $footer-height;
    padding: $footer-img-top-bottom-padding $footer-img-side-padding;
  }
}

/*Opera Fix*/
body:before {/* thanks to Maleika (Kohoutec)*/
  content: '';
  float: left;
  height: 100%;
  margin-top: -32767px;/* thank you Erik J - negate effect of float*/
  width: 0;
}
