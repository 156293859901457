

/* BEGIN MESSAGE FLASH */

.rg-flash {
  text-align: center;
}

.rg-alert {
  min-height: 20px;
  margin: 0px;
  font-size: 1.1em;
  font-weight: unset;
  z-index: 500;
  background-clip: padding-box;
  border: 1px solid #aaa;
}

@mixin br-alert-variant($background, $border, $color) {
  color: $color;
  background-color: $background;
  border-color: $border;

  .alert-link {
    color: darken($color, 10%);
  }
}

.rg-alert-success,.rg-alert-I {
  @include br-alert-variant(theme-color-level("info", $alert-bg-level), theme-color-level("info", $alert-border-level), theme-color-level("info", $alert-color-level));
}

.rg-alert-warning,.rg-alert-W {
  @include br-alert-variant(theme-color-level("warning", $alert-bg-level), theme-color-level("warning", $alert-border-level), theme-color-level("warning", $alert-color-level));
}

.rg-alert-danger,.rg-alert-E {
  @include br-alert-variant(theme-color-level("danger", $alert-bg-level), theme-color-level("danger", $alert-border-level), theme-color-level("danger", $alert-color-level));
}
 
.formError {
  color: #F00;
}

/* END MESSAGE FLASH */

/* Style for error messages */

/* ruby on rails input field with an error, emulate uk-form-danger */
.field_with_errors input[type="text"], 
.field_with_errors input[type="password"], 
.field_with_errors input[type="select"],
.field_with_errors input[type="radio"],
.field_with_errors input[type="checkbox"] {
  border: 1px solid #dc8d99;
  background-color: #fff7f8;
  color: #d85030;
}

/* ruby on rails error text */
div#errorExplanation {
  margin: 0px;
  padding: 0px;
}

div#errorExplanation h2 {
  border: 0px none white;
  color: red;
  font-weight: bold;
  border-bottom: 1px solid red;
  font-size: 10px;
  line-height: 13px;
  padding-top: 8px;
  padding-bottom: 3px;
}

div#errorExplanation p {
  border: 0px none white;
  color: red;
  font-size: 9px;
  line-height: 12px;
}

div#errorExplanation li {
  color: red;
  font-size: 9px;
  line-height: 12px;
  list-style: none;
  padding-bottom: 5px;
}
