.floorplan-container {
  position: relative;
}
.floorplan-container img {
  height: auto;
  max-width: 100%;
}

div.region {
  border: 1px solid #000;
  position: absolute;
  z-index: 1;
  opacity: 0.5;
  text-align: center;
  font-size: 11px;
  cursor: default;
}

div.region:hover {
  opacity: 0.75 !important;
}

.region-name {
  -webkit-text-stroke: 1px black;
  font-size: 24px;
  color: white;
  text-shadow: 1px 1px 0 #000000,
  1px -1px 0 #000000,
  -1px 1px 0 #000000,
  -1px -1px 0 #000000,
  1px 0px 0 #000000,
  0px 1px 0 #000000,
  -1px 0px 0 #000000,
  0px -1px 0 #000000;
  position: absolute;
  right: -150%;
  left: -150%;
  pointer-events: none;
}

.region-name-container {
  height: 30px;
  overflow: visible;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  font-size: 8em;
  word-wrap: none;
  position: relative;
}

.floorplan-container.hide-regions .region {
  display: none;
}


.youarehere {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  background: rgba(238, 31, 36, 1.0);
  font-size: 22px;
  text-transform: uppercase;
  color: #FFF;
  border: 1px solid #fff32f;
  z-index: 1001;
}

.youarehere::after {
  content: 'you are here';
  width: 100px;
  position: absolute;
  top: -9px;
  left: 18px;
  padding: 3px;
  color: red;
  font-size: 18px;
  text-shadow: 0 0 3px #00000042, 0 0 7px #0000007a;
  border: 1px solid #000;
  background-color: rgba(238, 31, 36, 0.9);;
  font-size: 23px;
  text-transform: uppercase;
  color: #fff;
}
