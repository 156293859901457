

$chateaux-primary-color: #6fa8a5;
$chateaux-secondary-color: #846934;
$chateaux-secondary-color: #adbd70;
$chateaux-secondary-color: #599239;

// I prefer these colors
$chateaux-primary-color: #46797b;
$chateaux-secondary-color: #231f20;

$chateaux-button-color1: $chateaux-primary-color;
$chateaux-logo: $chateaux-primary-color;
$chateaux-primary: $chateaux-primary-color;
$chateaux-secondary: $chateaux-secondary-color;
$chateaux-success-color: #4d9231;
$chateaux-info-color: #60a9c8;

$chateaux-nav-color: #000;
$link-color: #246f71;
$link-hover-color: lighten($link-color, 10%);

$btn-border-color: $chateaux-button-color1;
$button-hover-color: lighten($chateaux-button-color1, 10%);
$primary-link-color: $link-color;
$primary-dialog-hover: lighten($primary-link-color, 10%);

$button-color: $chateaux-primary-color;
$button-background: darken($chateaux-primary-color, 10%);

$button-color: lighten($chateaux-primary-color, 50%);
$button-background: darken($chateaux-primary-color, 10%);

$button-hover-background: lighten($button-background, 10%);
$button-hover-color: lighten($button-color, 10%);

$primary-logo-color: $chateaux-logo;
$primary:       $chateaux-primary;
$secondary:     $chateaux-secondary;
$success:       $chateaux-success-color;
$info:          $chateaux-info-color;

$form-focus-border:                              lighten($chateaux-primary-color, 20%);
$form-focus-background:                          #f5fbfe !default;
$form-focus-color:                               $chateaux-primary-color;

$navbar-nav-active-background: #fff;
$branded-background-color: #f5f5f5;

$panel-background-color: rgba(255, 255, 255, .9);
$panel-border-color: #4d4d4d;

$component-active-bg: $form-focus-border;
$input-focus-border-color: $form-focus-border;

$base-link-color:                                $chateaux-primary-color;
$base-link-hover-color:                          darken($chateaux-primary-color, 10%);

@import "portal-base";

html {
  background-color: $panel-background-color;
  body {
    background-color: $panel-background-color;
  }
}
