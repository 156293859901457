
$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;
$gray-100: #f8f9fa !default;
$gray-600: #6c757d !default;
$gray-800: #343a40 !default;

$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

$theme-colors: (
  'facebook': #1877f2,
  'twitter': rgb(0, 140, 191),
  'google_oauth2': #ffffff,
  'linked_in': #0077b5,
  'instagram': #e33e5c,
  'qrcode': $dark,
  'bluerim-admin': #10757a
);

$panel-background-color: rgba(245, 245, 245, .9) !default;
$panel-border-color: #4d4d4d !default;

.alert-bluerim-admin {
  &::before {
    content: '* * * Admins Only * * *';
    font-weight: bold;
  }
}

