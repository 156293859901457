

// Define path for fontawesome
$fa-font-path: "~@fortawesome/fontawesome-pro/webfonts";
// $font-family-icon: "Font Awesome 5 Pro";

@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~@fortawesome/fontawesome-pro/scss/duotone";

$branded-background-color: "transparent" !default;

$gears-image: "../images/gears_loading_bluerim.svg" !default;

@import "./portal-branding-base";
@import "~bootstrap/scss/functions";
$primary: theme-color("primary") !default;
$primary-link-color: $primary !default;
$link-color: $primary-link-color !default;

@import "~bootstrap/scss/variables";

$primary-dialog-hover: lighten($primary-link-color, 10%) !default;



@import '~bootstrap';
@import './bootstrap-switch/bootstrap-switch';
@import './tempusdominus/_tempusdominus-bootstrap-4';


$base-link-color: $link-color;
$base-link-hover-color: $link-hover-color;
$progress-bar-background: $primary;
$icon-font-path: "~uikit/dist/fonts";

$base-selection-background: theme-color("primary");
@import "./uikit-variables";

.uk-form-danger,.uk-form-success {
  border-radius: 0.25rem;
}
select.uk-select {
  border-radius: 0.25rem;
}

@import "~uikit/dist/scss/uikit-mixins";
@import "~uikit/dist/scss/uikit";
@import "~uikit/dist/scss/components/tooltip";
@import "~uikit/dist/scss/components/slideshow";
@import "~uikit/dist/scss/components/progress";
@import "~uikit/dist/scss/core/offcanvas";

@import "~nvd3/build/nv.d3.css";
@import "./content";
@import "./dialog";
@import "./footer";
@import "./location";
/*
*= import graph
 */
@import "./graph";
@import "./message";

@import "./floating-labels";



@import "./form_validation/index";

.portal-type-self_onboarding_portal {
  .address-field {
    display: none;
    &.address-field-address2{
      display: block;
    }
    &.show-address-fields{
      display: block;
    }
  }
}

.uk-navbar-brand.navbar-bluerim-logo {
  width:185px;
}
body.portal-style-smartaira {
  .uk-navbar-brand.navbar-bluerim-logo {
    width:280px;
  }
}


.uk-navbar-brand.uk-navbar-center.uk-visible-small .uk-navbar-brand.navbar-bluerim-logo {
  width:145px;
}

#main {
  /*<%#background-image: url(<%#= portal_asset_data_uri 'web_top_left.png' %>*/
  /*
  background-repeat:no-repeat;
  background-attachment:fixed;
  background-position:top left;
   */
  background-color: $branded-background-color;
}

.rg-form {
  @extend .card;
  @extend .bg-light;
  @extend .border-secondary;
}

.uk-form input.uk-button.rg-button {
  font-size: inherit;
  line-height: inherit;
  margin-bottom:5px;
}

.rg-button,input.rg-button {
  @extend .btn;
  @extend .btn-primary;
  min-width:112px;
  width:unset;
  display:block;
}

.rg-button.rg-button-inline {
  display:inline-block;
}

.rg-button.br-align-center {
  margin-left:auto;
  margin-right:auto;
}

.rg-button.uk-width-1-2 {
  width:50%;
}
.rg-button.uk-width-1-3 {
  width:33%;
}

.hr {
  width:100%;
  div.or:before,div.or:after{
    display:block;
    -webkit-box-flex: 1;
    -ms-flex-positive:1;
    flex-grow: 1;
    height:1px;
    background-color:#cccccc;
    content:'';
  }
  div.or:before{
    margin-right:8px;
  }
  div.or:after{
    margin-left:8px;
  }
  div.or {
    line-height:24px;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items: center;
    color:#808080;
    margin-top:5px;
    margin-bottom:5px;
    font-size:0.8125rem;
  }
}


.rg-button {
  @include button-variant($primary, $primary);
  /*
  &:hover {
    color: $body-color;
    text-decoration: none;
  }
   */
}

/* TODO: WARNING Fix this*/
/* Extending a compound selector, .btn:hover, is deprecated and will not be supported in a future release. */
/* Consider "@extend .btn, :hover" instead. */
.social-login-btn {
  @extend .btn;
}

.uk-form.rg-form.dual-column {
  flex-direction: inherit;
}

.uk-form select,
.uk-form select.uk-select,
.uk-form textarea,
.uk-form input:not([type]),
.uk-form input[type="text"],
.uk-form input[type="password"],
.uk-form input[type="datetime"],
.uk-form input[type="datetime-local"],
.uk-form input[type="date"],
.uk-form input[type="month"],
.uk-form input[type="time"],
.uk-form input[type="week"],
.uk-form input[type="number"],
.uk-form input[type="email"],
.uk-form input[type="url"],
.uk-form input[type="search"],
.uk-form input[type="tel"],
.uk-form input[type="color"] {
  @extend .form-control;
  width:unset;
  display:unset;
  &.uk-width-1-1 {
    width:100%;
  }
  &.uk-width-1-2 {
    width:50%;
  }
  &.uk-width-1-3 {
    width:33%;
  }
  &.uk-width-1-4 {
    width:25%;
  }
  &.uk-form-small {
    height: 25px;
    padding: 3px 3px;
    font-size: 12px;
  }
}

.left-grid {
  width:42%;
}
.right-grid {
  width:53%;
  margin-left:5%;
}
.left-grid,.right-grid {
  float:left;
}

.br-navbar .uk-navbar-center {
  max-width:65%;
}
.hidden{
  display:none;
}

.uk-form input.rg-button {
  border-radius: 0.25rem;
  color: $button-color;
  background-color: $button-background;
  &:hover {
    color: $button-hover-color;
    background-color: $button-hover-background;
  }
}

.loading-overlay,.button-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 36%;
  height: 100%;
  width: 100%;
  background-color: rgba(245,245,245,0.8);
  border-radius: 3px;
  z-index: 1;
}
.button-loading-overlay {
  padding-top: 10px;
}
.button-loading-overlay-wrapper {
  position:relative;
}
.loading-overlay > i.far {
  font-size:60px;
}
.button-loading-overlay > i.far {
  /*font-size:10px;*/
}
/*865*/

.owner-login-button {
  @extend .uk-button;
  @extend .rg-button;
  border-color: #000;
}

.form-within-button {
  border-color: $button-background !important;
}

.uk-width-large-2-3.br-align-center {
  margin-left: auto;
  margin-right: auto;
}

button.disabled {
  cursor: default;
  pointer-events: none;
}
